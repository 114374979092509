.banner-invest {
    padding-top: 4em;
    text-align: center;
    width: 100%;
    background-image: url("../graphics/trucks.jpg");
    background-position: center;
    height: 12em;
    margin-bottom: 1em;
}
.banner-title {
    background-color: white;
    opacity: 0.5;
    width: 40%;
    margin-left: 30%;
}
.pic {
    margin-left: 20%;
    margin-bottom: 2em;
    width: 60%;
    height: 40%;
}
.margin-top{
    margin-top: 1em;
}
.files-container {
    margin-top: 2em;
    height: 35em;
    margin-bottom: 5em;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
}
.files-container::-webkit-scrollbar {
    display: none;
}
.file-row-annual{
    width: 50%;
    margin-left: 25%;
    text-align: center;
    margin-bottom: 1em;
}
.file-row-10k{
    width: 80%;
    margin-left: 10%;
    text-align: center;
    margin-bottom: 1em;
}
.file-row-8k {
    width: 90%;
    margin-left: 5%;
    text-align: center;
}
.file-row-8k-bot {
    width: 90%;
    margin-left: 5%;
    text-align: center;
    margin-bottom: 1em;
}
.file-row-other {
    width: 90%;
    margin-left: 5%;
    text-align: center;
    margin-bottom: 1em;
}
a {
    text-decoration: none;
    color: #22419c;
}
a:visited {
    text-decoration: none;
    color: #dc1300;
}
.divider-col {
    border-right: 1px solid black;
}
.info-state {
    margin-bottom: 1em;
}

@import url("https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,500,600,700,800,900");
* {
    font-family: "Raleway", sans-serif;
}

.tagline{
    margin-top: 0.5em;
    color: #77cc00;
    font-style: italic;
    text-align: center;
    font-weight: bold;
}

.content {
    position: relative;
    left: 50%;
    margin-top: 3em;
    padding-bottom: 1em;
}
@media (max-width: 480px) {
    .content h2 {
        color: #fff;
        left: 0;
        font-size: 3rem !important;
        position: absolute;
        transform: translate(-50%, -50%);
    }
}

.content h2 {
    color: #fff;
    font-size: 5rem;
    position: absolute;
    transform: translate(-50%, -50%);
}

.content h2:nth-child(1) {
    color: white;
    -webkit-text-stroke: 2px #22419c;
}

.content h2:nth-child(2) {
    color: #dc1300;
    animation: animate 4s ease-in-out infinite;
    opacity: 1;
}
.content h2:nth-child(3) {
    color: #dc1300;
    animation: animate2 4s ease-in-out infinite;
    opacity: 0.5;
}
.content h2:nth-child(4) {
    color: #dc1300;
    animation: animate3 4s ease-in-out infinite;
    opacity: 0.2;
}


@keyframes animate {
    0%,
    100% {
        clip-path: polygon(
                0% 45%,
                16% 44%,
                33% 50%,
                54% 60%,
                70% 61%,
                84% 59%,
                100% 52%,
                100% 100%,
                0% 100%
        );
    }

    50% {
        clip-path: polygon(
                0% 60%,
                15% 65%,
                34% 66%,
                51% 62%,
                67% 50%,
                84% 45%,
                100% 46%,
                100% 100%,
                0% 100%
        );
    }
}
@keyframes animate2 {
    0%,
    100% {
        clip-path: polygon(
                5% 50%,
                21% 49%,
                38% 55%,
                59% 65%,
                75% 66%,
                89% 64%,
                105% 57%,
                105% 105%,
                5% 100%
        );
    }

    50% {
        clip-path: polygon(
                5% 65%,
                20% 70%,
                39% 71%,
                56% 67%,
                72% 55%,
                89% 50%,
                105% 51%,
                105% 105%,
                5% 105%
        );
    }
}
@keyframes animate3 {
    0%,
    100% {
        clip-path: polygon(
                10% 60%,
                26% 54%,
                43% 60%,
                64% 70%,
                80% 74%,
                94% 69%,
                110% 62%,
                110% 110%,
                10% 110%
        );
    }

    50% {
        clip-path: polygon(
                10% 70%,
                25% 75%,
                44% 76%,
                61% 72%,
                77% 60%,
                94% 55%,
                110% 56%,
                110% 110%,
                10% 100%
        );
    }
}
.bg-primary {
    background-color: #22419c !important;
}
.bg-secondary {
    background-color: #dc1300;
}
.link{
    color: white !important;
}
.foot {
    justify-content: center;
}

.footy {
    padding: .5em;
}

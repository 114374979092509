@import url(https://fonts.googleapis.com/css?family=Istok+Web);

.opacity-cover {
    width: 100%;
    background-color: white;
    opacity: 0.4;
    z-index: 3;
    position: relative;

}

.logo {
    position: absolute;
    z-index: 5;
    opacity: 1;
    top: 20%;
    bottom: 50%;
    right: 60%;
}

.bg {
    object-fit: fill;
}

@keyframes slidy {
    0% { left: 0%; }
    20% { left: 0%; }
    25% { left: -100%; }
    45% { left: -100%; }
    50% { left: -200%; }
    70% { left: -200%; }
    75% { left: -300%; }
    95% { left: -300%; }
    100% { left: -400%; }
}
* {
    box-sizing: border-box;
}
figure {
    margin: 0; background: #101010;
    font-family: Istok Web, sans-serif;
    font-weight: 100;
}
div#captioned-gallery {
    width: 100%; overflow: hidden;
    position: sticky
}
figure.slider {
    position: relative; width: 500%;
    font-size: 0; animation: 30s slidy infinite;
}
figure.slider figure {
    width: 20%; height: auto;
    display: inline-block;  position: inherit;
}
figure.slider img { width: 100%; height: auto; }
figure.slider figure figcaption {
    position: absolute; bottom: 0;
    background: rgba(0,0,0,0.4);
    color: #fff; width: 100%;
    font-size: 2rem; padding: .6rem;
}
.map-cont {
    position: sticky;
    top: 4em;
    width: 90%;
    margin-left: 5%;
    margin-top: 2em;
    margin-bottom: 2em;
}
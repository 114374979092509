body {
    background: white;
}
.home-intro{
    text-align: center;
}
.intro-p{
    text-align: center;
}
.home-pic-container {
    padding-top: 5em;
    margin-top: 4em;
    margin-left: 5%;
    padding-bottom: 2em;
    margin-bottom: 3em;
    width: 90%;
    height: 60%;
    background-image: url("../graphics/homepic1.jpg");
    background-color: #cccccc;
    background-size: cover;
    border-radius: 25px;
}
.bottom-right-shadow
{
    box-shadow: 10px 10px  5px rgba(0,0,0,0.6);
    -moz-box-shadow: 10px 10px  5px rgba(0,0,0,0.6);
    -webkit-box-shadow: 10px 10px  5px rgba(0,0,0,0.6);
    -o-box-shadow: 10px 10px  5px rgba(0,0,0,0.6);
}
.home-card {
    background-color: #22419c;
    color: white;
    max-width: 80%;
    max-height: 80%;
    overflow-y: hidden;
    margin-left: 10%;
    padding-left: 1em;
    padding-right: 1em;
}
.home-prod-card {
    background-color: white;
    color: #22419c;
    max-width: 80%;
    margin-top: 4em;
    margin-left: 10%;
    padding-left: 1em;
    padding-right: 1em;
    text-align: center;
}

.semi-pic-cont {
    height: 15em;
    width: 100%;
    background-image: url(../graphics/machine.jpg);
    background-color: white;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
}
.aero-pic-cont {
    height: 15em;
    width: 100%;
    background-image: url(../graphics/rocket.jpg);
    background-color: #22419c;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
}
.mri-pic-cont {
    height: 15em;
    width: 100%;
    background-image: url(../graphics/nurse.jpg);
    background-color: #22419c;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
}
.prod-col {
    text-align: center;
}
.prod-col-center {
    margin-right: 2em;
    margin-left: 2.5em;
}

.story-cont {
    border-top: 2px solid #dc1300;
    border-bottom: 2px solid #dc1300;
    padding-right: 2em;
    padding-left: 2em;
    margin-bottom: 2em;
    padding-top: 1em;
}
.story-photo {
    width: 100%;
}
.story-row {

    /*border-right: 1px solid #22419c;*/
    /*border-left: 1px solid #22419c;*/
    border-bottom: 1px solid #22419c;
    margin-bottom: 1em;
}
.story-title{
    padding-left: 2em;
    margin-bottom: 1em;
}
.story-other {
    margin-bottom: 1em;
    color: #dc1300;
    font-size: .75em;
}
.chart{
    margin-left: 5.75em;
    padding-left: 1em;
    padding-right: 2em;
}
@media (max-width: 480px){
    .chart{
        margin-left: 0em;
        padding-left: 1em;
        padding-right: 1em;
    }
}
.load {
    width: 100%;
}
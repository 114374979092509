
.banner-about {
    padding-top: 4em;
    text-align: center;
    width: 100%;
    background-image: url("../graphics/generator.jpg");
    background-position: center;
    height: 12em;
}
.banner-title {
    background-color: white;
    opacity: 0.5;
    width: 40%;
    margin-left: 30%;
}
.about-title {
    margin-top: 1em;
    margin-left: .5em;
}

.staff-row {
    margin-bottom: 2em;
}
.staff-card {
    padding: 1.5em 0 .5em;
    border-radius: 2em;
    text-align: center;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    width: 18em;
    height: 30em;
    margin-bottom: .75em;
    overflow-y: hidden;
    overflow-x: hidden;
    -ms-overflow-style: none;  /* IE and Edge */

}
.advisor-card {
    padding: 1.5em 0 .5em;
    border-radius: 2em;
    text-align: center;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    width: 18em;
    margin-bottom: .75em;
    height: 13.5em;
}
.staff-img {
    width: 65%;
    height: 12em;
    border-radius: 50%;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
}
.staff-name {
    font-weight: bold;
}
.staff-title {

}
.staff-inner-cont{
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
}
.advisor-inner-cont{
    overflow-x: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
}
.staff-desc {
    font-style: italic;
    padding: 1em;
}
.advisor-cont{
    margin-left: 5%;
    padding-bottom: 1.5em;
}
.team-cont{
    margin-left: 5%;
    margin-top: 1em;
    margin-bottom: 2em;
    max-height: 40em;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
}
.team-cont::-webkit-scrollbar {
    display: none;
}
.padding {
    padding-left: 1.5em;
    padding-right: 1.5em;
}

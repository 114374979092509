.bg-primary {
    background-color: #22419c !important;
}
.bg-secondary {
    background-color: #dc1300;
}
.link{
    color: #dc1300 !important;
}
.navy {
    background-color:  #22419c
}
.link:hover {
    /*-webkit-filter: blur(0.5px); !* Chrome, Safari, Opera *!*/
    /*filter: blur(0.5px);*/
    /*font-weight: bold;*/
    text-shadow: 0 0 0 #dc1300;
    animation: smoky 10s;
}
.navbar-toggler {
    margin-right: 1rem;
    border-color: #7c0 !important;
    color: #7c0 !important;
}
.nav-logo {
    width: 80%;
    height: 4.5em;
    background-image: url("../graphics/AMGAS-words.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: -1em;
}
.nav-logo > a {
    width: 4em;
    opacity: 0.0;
}

.link:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #dc1300;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.link:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

@keyframes smoky {
    to {
        transform:
                scale(1.3);

        text-shadow: 0 0 20px #22419c;
        opacity: 0;
    }
}
.value-banner {
    width: 100%;
    background-color: #22419c;
    padding-left: 10%;
    padding-right: 10%;
    text-align: center;
    color: white;
}
.ticker {
    background-color: #22419c;
    color: white;
    padding-right: 0.5%;
}

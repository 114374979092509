.banner {
    padding-top: 4em;
    text-align: center;
    width: 100%;
    background-image: url("../graphics/rocket.jpg");
    background-position: center right;
    height: 12em;
    margin-bottom: 2em;
}
.frack-pic{
    background-image: url("../graphics/fracking.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 90%;
    margin: 1em 5% 1em;
    height: 25em;
}
.map-pic{
    background-image: url("../graphics/hugo-map.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    margin: 1em 0 1em;
    height: 25em;
}
.banner-title {
    background-color: white;
    opacity: 0.5;
    width: 40%;
    margin-left: 30%;
}
.nav-link {
    margin-left: 1em;
    background-color: #22419c !important;
    color: white !important;
    cursor: pointer;
}
.tabby.active {
    color: white !important;
    background: #dc1300 !important;
}
.prod-bod {
    padding: 2em;
}
.title {
    text-align: center;
    padding-top: 2.5em;
    padding-left: .25em;
    color: white !important;
}
.title-oil {
    text-align: center;
    padding-top: 2.5em;
    padding-left: 2em;
    padding-right: 2.25em;
    color: white !important;
}
.title-pic {
    background-image: url("../graphics/default-thumb.png");
    background-size: cover;
    background-color: white;
    height: 8em;
    width: 12em;
    text-align: center;
    font-weight: bold;
    color: black;
}
.prod-cont {
    margin-bottom: 1em;
    background-color: #22419c;
    border-radius: 1em;
    height: 8em;
    box-shadow: 10px 10px  5px rgba(0,0,0,0.6);
    -moz-box-shadow: 10px 10px  5px rgba(0,0,0,0.6);
    -webkit-box-shadow: 10px 10px  5px rgba(0,0,0,0.6);
    -o-box-shadow: 10px 10px  5px rgba(0,0,0,0.6);
}
@media (max-width: 480px) {
    .prod-cont {
        width: 95%;
        margin-top: 1em;
        margin-left: 2.5%;
        margin-right: 5%;
    }
    .nav-link {
        margin-left: 0.5em;
    }

}

